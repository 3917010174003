import React from 'react';
import { Link, graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from "@mdx-js/react";
import { StaticImage } from 'gatsby-plugin-image';


import Layout from '../components/layout';
import PartnerCard from '../components/PartnerCard';
import CallToAction from '../components/CallToAction';

const BranchePost = ({ location, data, pageContext }) => {
  
  const shortcodes = {CallToAction};
  
  const services = data.mdx.frontmatter.services;

  return (
    <Layout title={data.mdx.frontmatter.title} description={data.mdx.excerpt} heroTitle={' '} imageSrc={data.mdx.frontmatter.hero_image.childImageSharp.gatsbyImageData.src} imageData={data.mdx.frontmatter.hero_image.childImageSharp.gatsbyImageData} pageContext={pageContext}>
      <section className="section">
        <div className="container">
          <div className="columns is-full">
            <div className="column has-text-left">
              <h1 className="title">
                {data.mdx.frontmatter.title}
              </h1>
            </div>
          </div>
          
          <div className="content">
            <MDXProvider components={shortcodes}>
              <MDXRenderer>{data.mdx.body}</MDXRenderer>
            </MDXProvider>
          </div>

        </div>
      </section>
      <section className="section slant-top slant-bottom" style={{background:"#f2f2f2"}}>
        <div className="columns">
          <div className="column">
          
            <div className="block" >
              <div className="container">
                <h1 className="title">Unsere Leistungen im Bereich {data.mdx.frontmatter.title}</h1>
                
                <div className="columns is-multiline">
                {
                  services.map((service) => {
                    const backgroundImageStack = [
                      `radial-gradient(at 5% top, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.9))`
                    ];
                    const imageData = service.frontmatter.hero_image.childImageSharp.gatsbyImageData;
                    if (imageData) backgroundImageStack.push(imageData);
                    
                    return (
                      <div className="column is-full">
                        <div className="box">
                          <div className="subtitle mb-2">
                            {service.frontmatter.title}
                          </div>
                          <div className="content mb-2">
                            {service.frontmatter.description}
                          </div>
                          {
                            service.excerpt && <Link to={`/${service.slug}`} className="link offer-button">
                              Mehr erfahren...
                            </Link>
                          }
                        </div>
                      </div>
                    );
                  })
                }
                </div>
                
              </div>
            </div>
            
          </div>
        </div>
      </section>
      
      <section className="section">
        <div className="container">
          <div className="block">
            <h2 className="title">Ihre Ansprechpartner</h2>
            <div className="is-flex is-flex-wrap-wrap is-flex-align-items-stretch" style={{margin: "-0.75rem"}}>
            {
              data.mdx.frontmatter.authors.map((partner) => (
                <PartnerCard partner={partner} style={{margin: "0.75rem", flexBasis: "18rem"}} className="equal-height" />
              ))
            }
            </div>
          </div>
        </div>
      </section>

      <CallToAction title="Fragen?" subtitle={`Erfahren Sie mehr über unsere Leistungen im Bereich ${data.mdx.frontmatter.title}.`} />
      
    </Layout>
  );
}

export const pageQuery = graphql`query BrancheByID($id: String) {
  mdx(id: {eq: $id}) {
    frontmatter {
      title
      hero_image {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      hero_image_alt
      authors {
        frontmatter {
          partnerName
          description
          twitter
          linkedin
          email
          phone
          title
          pgp_keyfile {
            publicURL
            name
            extension
          }
          pgp_fingerprint
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        slug
        body
      }
      services {
        frontmatter {
          title
          description
          hero_image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        excerpt(truncate: true)
        slug
      }
    }
    body
    excerpt(pruneLength: 200)
  }
}
`;

export default BranchePost;